// export const primaryColor = '#F7931A'
// export const secondaryColor = '#F7D723' 

// export const primaryColor = '#04A71E'
// export const secondaryColor = '#90FFCE' 
// export const tertiaryColor = '#F7D723'

// export const primaryColor = '#FFC700'
// export const secondaryColor = '#FCFF53' 
// export const tertiaryColor = '#F7D723'
// export const quaternaryColor = '#fff3bc'

export const primaryColor = '#CF0000'
export const secondaryColor = '#FFA800' 
export const tertiaryColor = '#FF3232'
export const quaternaryColor = '#763636'