import React from 'react'
import { secondaryColor } from './constants'

const RetroButton = (props) => {
    const {bg, title,color="black",child=null} = props
    return (
        <div className='relative w-fit'>
            <div style={{background:bg || secondaryColor , color:color}} className={`cursor-pointer text-xl ${child && 'flex justify-between items-center gap-4'} border-2 border-black border-solid w-fit px-4 py-2 rounded-xl text-black border-r-4 border-b-8 hover:translate-y-1.5`}>
                {child}
                <p>{title}</p>
            </div>

        </div>
    )
}

export default RetroButton