import React from 'react'
import { secondaryColor } from './constants'

function SectionOne() {
    return (
        <section class="bg1">
            <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                <img class="w-full animate-bounce" src={require('../../Assets/NIWU/ni1.png')} alt="" />
                <div class="mt-4 md:mt-0">
                    <h2 style={{color:secondaryColor}} class="transform rotate-[-6deg] mb-4 text-[72px] tracking-tight font-extrabold text-[black] ">CHINESE NIWU</h2>
                    <p class="mb-6 font-light text-black text-[20px] transform rotate-[3deg]">Niwu, the Chinese counterpart of Neiro, is the epitome of a savage trader and audacious gambler. With the finesse of a seasoned poker player and the audacity of a high-stakes gambler, Niwu navigates the treacherous waters of trade with an almost infuriating ease. His razor-sharp instincts and fearless tactics often leave his rivals scrambling to keep up, while he smirks and collects his winnings. Beneath that charming facade lies a cunning strategist who knows how to turn every situation to his advantage, making him the person everyone loves to hate and hates to admire.
</p>
                    <a href="https://t.me/NIWUPORTAL" class="inline-flex items-center text-white bg-black hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-[32px] px-5 py-2.5 text-center dark:focus:ring-primary-900">
                        BUY NOW
                        <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </a>
                </div>
            </div>
        </section>
    )
}

export default SectionOne