// import logo from './logo.svg';
import './App.css';
// import One from './One';
import Badz from './Components/Badtz/Badz'

function App() {
  return (
    <div>
      <Badz/>
    </div>
  );
}

export default App;
