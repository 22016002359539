import React from 'react'
import Nav from './Nav'
import Home from './Home'
import './Badz.css'
// import Slider from './Slider'
import BadzSlider from './BadzSlider'
import SectionOne from './SectionOne'
import Footer from './Footer'
import How from './How'
import Tokenomics from './Tokenomics'
// import Why from './Why'
// import Hom from './Hom'
// import { primaryColor } from './constants'

function Badz() {
  return (
    <div className='bg1'>
        <Nav/>
        {/* <Hom/> */}
                <Home/>
        {/* {/* <BadzSlider/> */}
        {/* {/* <Slider/> */}
        <BadzSlider/>
        <SectionOne/>
        <BadzSlider/>


        <Tokenomics/>
        <BadzSlider/>

        <How/>
        <BadzSlider/>

        <Footer/>
    </div>
  )
}

export default Badz