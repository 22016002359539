import React from 'react'
import { secondaryColor } from './constants'

function How() {
    return (
        <div className='bg1 border-t-[10px] border-[#e6d926] id="road'>
            <div className='absolute h-[400px] w-[100vw] overflow-clip'>
                <img className='absolute h-[150px] right-[-30px] top-[200px]' src={require('../../Assets/NIWU/gif1.gif')} />
                <img className='absolute h-[150px] left-[-30px] top-[200px]' src={require('../../Assets/NIWU/gif2.gif')} />
                <img className='absolute h-[150px] left-[-30px] top-[700px]' src={require('../../Assets/NIWU/gif3.gif')} />
                <img className='absolute hidden md:block h-[200px] right-[0px] top-[500px]' src={require('../../Assets/NIWU/gif1.gif')} />
                <img className='absolute hidden md:block h-[200px] left-[0px] top-[500px]' src={require('../../Assets/NIWU/gif2.gif')} />
                <img className='absolute hidden md:block h-[200px] right-[500px] top-[100px]' src={require('../../Assets/NIWU/gif1.gif')} />
                <img className='absolute hidden md:block h-[200px] left-[800px] top-[100px]' src={require('../../Assets/NIWU/gif2.gif')} />
                <img className='absolute hidden md:block h-[200px] left-[300px] top-[100px]' src={require('../../Assets/NIWU/gif1.gif')} />
            </div>
            <section class="">
                <div class="py-8 px-4 mx-auto max-w-screen-xl lg:py-16 lg:px-6 ">
                    <div class="mx-auto max-w-screen-sm text-center mb-8 lg:mb-16">
                        <h2 class="mb-4 text-[72px] tracking-tight font-extrabold text-black ">NIWU TV</h2>
                        {/* <p class="font-light text-gray-500 lg:mb-16 sm:text-xl">Explore the whole collection of open-source web components and elements built with the utility classes from Tailwind</p> */}
                    </div>
                    <div style={{ borderColor: secondaryColor }} className='border-8'>
                        <video src={require('../../Assets/NIWU/vv1.mp4')} autoPlay loop muted class=" rounded-lg sm:rounded-none sm:rounded-l-lg" alt="Bonnie Avatar" />
                    </div>
                </div>
            </section>
        </div>
    )
}

export default How