import React from 'react'
import Marquee from 'react-fast-marquee'
import { primaryColor, quaternaryColor, secondaryColor, tertiaryColor } from './constants'

function BadzSlider() {
  return (
    <div className='relative transform rotate-[2deg] border-y-4 border-[#FFA800]'>
      <section style={{ background: '#250e08' }} className='w-[100vw] bg1   py-2 max-w-[100%] flex items-center'>
        <Marquee direction='right' >
          <div className='flex gap-[60px] items-center '>
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni0.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif1.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni3.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif2.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni4.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif3.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni2.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif1.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni0.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif1.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni3.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif2.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni4.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif3.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni2.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif1.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni0.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif1.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni3.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif2.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni4.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif3.gif')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/ni2.png')} />
            <img className='h-[80px]' src={require('../../Assets/NIWU/gif1.gif')} />
          </div>
        </Marquee>
      </section>

    </div>
  )
}

export default BadzSlider