import React from 'react'
import RetroButton from './RetroButton'
import CardEffect from './CardEffect'
import { primaryColor, quaternaryColor, secondaryColor, tertiaryColor } from './constants'
import './Badz.css'
function Home() {
  return (
    <div className='bg1' >
      <div id="home">
        <div className='absolute h-[100vh] w-[100vw] overflow-clip'>
            <img className='absolute h-[150px] right-[-30px] top-[200px]' src={require('../../Assets/NIWU/gif1.gif')}/>
            <img className='absolute h-[150px] left-[-30px] top-[200px]' src={require('../../Assets/NIWU/gif2.gif')}/>
            <img className='absolute h-[150px] left-[-30px] top-[700px]' src={require('../../Assets/NIWU/gif3.gif')}/>
            <img className='absolute hidden md:block h-[200px] right-[0px] top-[500px]' src={require('../../Assets/NIWU/gif1.gif')}/>
            <img className='absolute hidden md:block h-[200px] left-[0px] top-[500px]' src={require('../../Assets/NIWU/gif2.gif')}/>
            <img className='absolute hidden md:block h-[200px] right-[500px] top-[100px]' src={require('../../Assets/NIWU/gif1.gif')}/>
            <img className='absolute hidden md:block h-[200px] left-[800px] top-[100px]' src={require('../../Assets/NIWU/gif2.gif')}/>
            <img className='absolute hidden md:block h-[200px] left-[300px] top-[100px]' src={require('../../Assets/NIWU/gif1.gif')}/>
        </div>
        <section class="border-[] " >
          <div class="gap-16 items-center pt-32 px-0 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-32 lg:pb-6 lg:px-6">
            <div class="font-light text-gray-500 sm:text-lg dark:text-gray-400">

              <p className='text-[72px] leading-[80px] text-black text-center md:text-left animate-wiggle' style={{ color: secondaryColor }}>CHINESE NIWU</p>
              <p className='text-[52px] leading-[80px] text-black text-center md:text-left'>The Chinese Mafia DOG</p>

              <div className='flex gap-4 justify-center md:justify-start w-full'>
                <a href='https://x.com/NIWUETH' className='transform rotate-[10deg]'>
                  <RetroButton child={<img className='h-[30px]' style={{ borderRadius: "100%", border: `2px solid ${primaryColor}` }} src={require('../../Assets/NIWU/ntw.png')} />} title="Twitter" bg={secondaryColor} />
                </a>
                <a href='https://t.me/NIWUPORTAL' className='transform rotate-[-10deg]'>
                  <RetroButton child={<img className='h-[30px]' style={{ borderRadius: "100%", border: `2px solid ${primaryColor}` }} src={require('../../Assets/NIWU/ntg.png')} />} title="Telegram" bg={secondaryColor} />
                </a>

              </div>
              <p className='text-[13px] md:text-[20px] leading-[80px] text-black text-center md:text-left font-sans font-bold'>CA: 0x5D8b552daF7351c49792acE1449EcF2f15e79181</p>

            </div>
            <div class="grid grid-cols-1 gap-4 mt-8">
              <img class="mt-4 h-[500px] md:h-[600px] w-full lg:mt-10 animate-wiggle" src={require('../../Assets/NIWU/ni3.png')} />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Home