import React from 'react'
import RetroButton from './RetroButton'
import { primaryColor, secondaryColor } from './constants'

function Nav() {
    return (
        <div>
            <nav style={{ background: primaryColor }} class="z-50 fixed w-full top-0 start-0  border-b-black border-b-[10px] ">
                <div class="max-w-screen-xl flex flex-wrap items-center justify-between mx-auto p-4">
                    <a href="#" class="flex items-center space-x-3 rtl:space-x-reverse">
                        <img src={require('../../Assets/NIWU/ni0.png')} class="h-16" alt="#" />
                        <span class="self-center text-2xl font-semibold whitespace-nowrap text-black italic"></span>
                    </a>
                    <div class="flex md:order-2 space-x-3 md:space-x-0 rtl:space-x-reverse">
                        <a href='https://t.me/NIWUPORTAL'>
                            <RetroButton title="Buy Now" bg={secondaryColor} />
                        </a>
                    </div>
                    <div class="items-center justify-between hidden w-full md:flex md:w-auto md:order-1" id="navbar-sticky">
                        <ul class="flex flex-col p-4 md:p-0 mt-4 font-medium border border-gray-100 rounded-lg  md:space-x-8 rtl:space-x-reverse md:flex-row md:mt-0 md:border-0  dark:border-gray-700">
                            <li>
                                <a href="#home" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page">
                                    <RetroButton title="Home" />
                                </a>
                            </li>
                            <li>
                                <a href="#about" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page">
                                    <RetroButton title="About NIWU" />
                                </a>
                            </li>

                            <li>
                                <a href="#why" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page">
                                    <RetroButton title="NIWU" />
                                </a>
                            </li>
                            <li>
                                <a href="#token" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page">
                                    <RetroButton title="TOKENOMICS" />
                                </a>
                            </li>
                            <li>
                                {/* <a href="#road" class="block py-2 px-3 text-white bg-blue-700 rounded md:bg-transparent md:text-blue-700 md:p-0 md:dark:text-blue-500" aria-current="page">
                                    <RetroButton title="Roadmap" />
                                </a> */}
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Nav