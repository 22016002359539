import React from 'react'

function Tokenomics() {
    return (
        <div className='bg1 border-t-8 border-white relative' id="token">
            <section class="">
                <div class="gap-8 items-center py-8 px-4 mx-auto max-w-screen-xl xl:gap-16 md:grid md:grid-cols-2 sm:py-16 lg:px-6">
                    <img class="w-full " src={require('../../Assets/NIWU/token.png')} alt="" />
                    <div class="mt-4 md:mt-0">
                        <h2 class="mb-4  tracking-tight font-extrabold text-gray-900  text-[72px]">TOKENOMICS</h2>
                        <p class="mb-6 font-light text-gray-900 text-[40px]">SUPPLY : 1,000,000,000,000 $NIWU</p>
                        <p class="mb-6 font-light text-gray-900 text-[40px]">NETWORK : ETH</p>
                        <p class="mb-6 font-light text-gray-900 text-[40px]">TAXES : 0% BUY & 0% SELL</p>

                        {/* <a href="#" class="bg-black inline-flex items-center text-white bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-[30px] px-5 py-2.5 text-center dark:focus:ring-primary-900">
                            BUY NOW
                            <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                        </a> */}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Tokenomics