import React from 'react'

function Footer() {
    return (
        <div>
            <footer class="bg-black border-white border-t-[10px]  shadow sm:flex sm:items-center sm:justify-between p-4 sm:p-6 xl:p-8  antialiased">
                <img class="mb-4 text-sm text-center text-black  sm:mb-0 h-[100px]" src={require('../../Assets/NIWU/ni2.png')}/>
                    
                
                <div class="flex justify-center items-center space-x-1">
                    
                    
                    
                </div>
            </footer>
        </div>
    )
}

export default Footer